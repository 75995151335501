import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Tooltip from "./tooltip";
import Link from './link.js';
import DrawerLink from "./open-drawer-link";

export default class SearchInventoryItems extends Component {
    constructor() {
        super();
        this.state = {
            key : 0,
        };
        
    }
    
    render() {
        return (
          <tr key={this.props.index} className="contact-row product-row" id={"contact-row-"+this.props.inventory.inventory_id}>
            <td width="70">         
              <div style={{backgroundImage:'url('+this.props.inventory.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width: '40px',border:'1px solid #d7d7d7'}}></div>
            </td>

            {(this.props.target==='product') &&
            <td><Link link={'/products/view/'+this.props.inventory.inventory_id} name={this.props.inventory.item_name} /></td>
            }
            {(this.props.target==='inventory') &&
            <>
                {(this.props.inventory.parent_id===0) &&
                    <td><Link link={'/products/view/'+this.props.inventory.inventory_id} name={this.props.inventory.item_name} style={{fontSize:'14px',fontWeight:'bold'}}/></td>
                }
                {(this.props.inventory.parent_id!==0) &&
                <td>
                  <Link link={'/products/view/'+this.props.inventory.inventory_id} name={this.props.inventory.parent_name} style={{fontSize:'14px',fontWeight:'bold'}}/><br/>
                  <Link link={'/variant/view/'+this.props.inventory.parent_id+'/'+this.props.inventory.inventory_id} name={this.props.inventory.item_name} style={{fontSize:'13px',fontWeight:'bold'}}/>
                </td>
                }
                </>
            }
            {(this.props.target==='inventory') &&
            <>
               <td>{this.props.inventory.selling_price}</td>
               <td>
                <p style={{margin:'0px'}}>
                {(this.props.inventory.child_barcode_info.length>1) &&
                  <DrawerLink target="different_barcode" url='' name={this.props.inventory.availability} schema=''  info={this.props.inventory.child_barcode_info} page_title={this.props.inventory.item_name}/>
                }
                {(this.props.inventory.child_barcode_info.length===1) &&
                  <span>{this.props.inventory.availability}</span>
                }
                </p>
                <span className="inv-dzn-lbl">{this.props.inventory.dozen_quantity}</span>
                <span className="inv-dzn-lbl sep">{this.props.inventory.seperator}</span>
                <span className="inv-dzn-lbl">{this.props.inventory.bundle_text}</span>
               </td>
            </>
            }
            <td>
                <a href={"/products/category/"+this.props.inventory.category_id}>{this.props.inventory.category}</a>
            </td>
            {(this.props.target==='inventory') &&
             <td className="text-center">
             <span style={{fontSize:'14px'}}>{this.props.inventory.barcode_name}</span>
             {/* {(value.barcode_name!=="" && value.barcode_name!==null && value.sku!=="" && value.sku!==null) &&
               <span style={{fontSize:'16px',color:'#acacac'}}>  |  </span>
             }
             <span style={{fontSize:'14px'}}>{value.sku}</span> */}
           </td>
            }
            {(this.props.target==='product') &&
            <>
                <td> 
                  {(this.props.inventory.varient>0) &&
                  <>
                      <span>{this.props.inventory.availability+' in stock for '+this.props.inventory.varient + ' variants'}</span>
                  </>
                  }
                  {(this.props.inventory.varient===0) &&
                  <>
                  {(this.props.inventory.child_barcode_info.length>1) &&
                    <DrawerLink target="different_barcode" url='' name={this.props.inventory.availability+' in stock'} schema='' info={this.props.inventory.child_barcode_info}  page_title={this.props.inventory.item_name}/>
                  }
                    {(this.props.inventory.child_barcode_info.length<=1) &&
                      <span>{this.props.inventory.availability+' in stock'}</span>
                    }
                  </>
                  }
                </td>

                <td>{this.props.inventory.selling_price}</td>
            </>
            }
            <td>{(this.props.inventory.inventory_status==='Draft')?'Inactive':this.props.inventory.inventory_status}</td>
            
            <td>
              <Tooltip target="inventory" id={this.props.inventory.inventory_id} index={this.props.index} view={"/products/view/"+this.props.inventory.inventory_id} edit={"/products/edit/"+this.props.inventory.inventory_id}  delete={{active_tab:'inventory',id:this.props.inventory.inventory_id,data_value:'inventory'}}  item_count={this.props.count}/>

            </td>
          </tr>
        );
    }
}