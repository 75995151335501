import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Tooltip from "./tooltip";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft,faCaretUp,faCaretLeft,faCaretRight,faSpinner, faSort} from '@fortawesome/free-solid-svg-icons';
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import SearchInventoryItems from './search-inventory-items.js';
import Autocomplete from "./autocomplete";
import BarcodeTemplate from "./settings/barcode-template";
import DrawerLink from "./open-drawer-link";
import ListCount from "./list-count";
import DownloadProducts from "./download-products.js";

let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;
let productList = [];
let cancelToken;
export default class Products extends Component {
    constructor() {
      super();
      this.state = {
          loading_process :'Loading...',
          number_of_page :0,
          per_page :25,
          download_inventory_list :[],
          number_of_row :2,
          prev_link :'',
          next_link: '',
          current_page : '',
          total_page : '',
          page_msg : '',
          current_url : '',
          prev_url : '',
          inventory_list : '',
          is_barcode_tab : false,
          item_options:[],
          barcode_template_data:[],
          barcode_template:[],
          active_tab:'active',
          inventory_search_items : [],
          search_count:0,
          active_count : 0,
          draft_count : 0,
          loss_making_count : 0,
          active_category : '',
          is_category : false,
          is_block_check : false,
          product_sort_type : 'ASC',
      };
      _this = this;
      this.timeout = null;
      this.inventory_search_items = [];
      this.searched_ids=[];
      this.barcode_template = [];
      this.is_access = check_access.is_access.bind(this);
      this.closeModel = this.closeModel.bind(this);
      this.handleInventorySearch = this.handleInventorySearch.bind(this);
      this.openPrintPreview = this.openPrintPreview.bind(this);
      this.handlePrint = this.handlePrint.bind(this);
      this.handleOpenMenu = this.handleOpenMenu.bind(this);
      this.handleInventoryHideShowOnAdd = this.handleInventoryHideShowOnAdd.bind(this);
      this.handleInventoryHideShowOnRemove = this.handleInventoryHideShowOnRemove.bind(this);
      
    }
    handleSortListOnMargin = () =>{
      this.handleManageSort('margin');
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          name : 'sort_product_on_margin',
          value : true,
      }
      let npcapi = app_api+'/save_option';
      axios.post(npcapi, send_data)
        .then(function (response) {
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
      
    }
    handleManageSort = (value)=>{
      let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        name : 'product_active_sort_column',
        value : value,
    }
    let npcapi = app_api+'/save_option';
    axios.post(npcapi, send_data)
      .then(function (response) {
        //window.location.reload();
    }.bind(this)).catch(function(res){
        
    })
    }
    handleOpenMenu(e){
      document.getElementById("add-product-dd").classList.toggle('hide');;
    }

    handleInventorySearch(e){
      if (typeof cancelToken != typeof undefined) {
          cancelToken.cancel("Operation canceled due to new request.");
          
          //return false;
      }
      cancelToken = axios.CancelToken.source();
      let item_name = e.target.value;
      this.inventory_search_items = [];
      this.setState({inventory_search_items:this.inventory_search_items});
      this.setState({search_count:1});
      this.setState({loading_process:'Loading...'});
      this.searched_ids = [];
      if(item_name!==""){
        let cancelledToken = cancelToken.token;
        let http_header = {cancelToken:cancelledToken};
        //clearTimeout(this.timeout);

        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              item_name : item_name,
              target : 'product'
           }
        let api = app_api+'/inventory/search_item';

       // this.timeout = setTimeout(function () {

        axios.post(api, body, http_header)
          .then(function (response) {
            let cursor = response.data;
            
            if(cursor.length>0){
              for(let i=0;i<cursor.length;i++){
                let item = cursor[i];
                
                if(_this.searched_ids.indexOf(item.inventory_id)<0){
                  _this.searched_ids.push(item.inventory_id);
                  _this.inventory_search_items.push(<SearchInventoryItems target="product" inventory={item} count={cursor.length} index={i}/>);
                }
              }
            }else{
              _this.setState({loading_process:'No Inventory found.'});
            }
            _this.setState({inventory_search_items:_this.inventory_search_items});
        }.bind(_this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
        //}, 1500);
      }else{
        this.setState({search_count:0});
        //clearTimeout(this.timeout);
      }
    }


    handlePrint(e){
/*
            let cls = document.querySelectorAll(".bcode-temp");
            for(let i=0;i<cls.length;i++){
                cls[i].classList.remove("mrbtm-5");
            }*/

            let type = e.currentTarget.id;
            var divContents = document.getElementById("main-container").innerHTML; 
            var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
            a.document.write('<html><head>'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">@page {margin: 0;}.bcode-temp{text-transform:uppercase}.react-draggable {position: absolute !important;line-height: 0;white-space: nowrap;}.react-draggable.center{position: relative !important;}.react-draggable.free{left: 0;}.react-draggable.free-left{left: 0;}.react-draggable.free-right{right: 0;}.react-draggable.left{position: relative !important;}.react-draggable.right{position: relative !important;}.drag-inner.center{text-align:center;}.drag-inner.right{text-align: right;}.drag-inner.left{text-align: left;}.temp-elem-name{margin: 0;}</style>'); 
            a.document.write('</head>'); 
            
            a.document.write('<body onload="window.print();" style="background:rgb(222, 222, 222)">'); 
            
            a.document.write(divContents); 
           
             a.document.write('</body></html>');
             a.document.close();
             a.focus();
    } 
    closeModel(event) {
      var element = document.getElementById('barcode-print-preview');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    openPrintPreview(event) {
      let thisid = event.currentTarget.id;
      /*document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      */
      let barcode_template_data = this.state.barcode_template_data;

      if(barcode_template_data[0].height!==undefined){
        let height = barcode_template_data[0].height;
        let width = barcode_template_data[0].width;
        let values = barcode_template_data[0].value;

        let name = this.state.barcode_template_data[0].name;

        let prows = document.querySelectorAll(".barcode-list");
        let template = '';
        this.barcode_template = [];
        for(let r=0 ; r<prows.length; r++){
          let id = prows[r].getAttribute("data-id");
          let item_options = JSON.parse(localStorage.getItem("item_options"));
          let billing_data = item_options[id];

          let quantity = document.getElementById("no-of-print-"+id).value;
          if(quantity!==""){
            quantity = parseInt(quantity);
            for(let q=0;q<quantity;q++){
              this.barcode_template.push(<BarcodeTemplate barcode_template_data={barcode_template_data} id={q} key={q} billing_data={billing_data} name={name} tamplate_data={values} height={height} width={width}/>);
            } 
            this.setState({barcode_template:this.barcode_template});
          }
        }

        var element = document.getElementById('barcode-print-preview');
        element.classList.remove("fade");
        element.style.display="block"; 
      }
     /* document.getElementById(thisid+'-spinner').classList.hide("hide");
      document.getElementById(thisid).classList.remove("hide");*/
    }

    handleInventoryHideShowOnAdd(){
      let item_options = JSON.parse(localStorage.getItem('item_options'));
      let barcode_list = document.querySelectorAll(".barcode-list");
      let bcode_par_item = localStorage.getItem('bcode_par_item');
      let new_item_options = [];
      for(let i=0;i<item_options.length;i++){
          if(document.getElementById("barcode-list-"+i)){
            continue;
          }
          if(bcode_par_item!==null && parseInt(bcode_par_item)===i){
            localStorage.removeItem("bcode_par_item");
            continue;
          }
          let dt = document.querySelectorAll('[data-target="'+i+'"]');
          let child_item_options = JSON.parse(localStorage.getItem('child_item_options'));
          if(dt.length>0 && dt.length===child_item_options[i].length){
            continue;
          }
          new_item_options[i] =item_options[i]; 
      }
      this.setState({item_options:new_item_options});
    }
    handleInventoryHideShowOnRemove(){
      let bcode_par_item = parseInt(localStorage.getItem('bcode_par_item'));
      let bcode_item = parseInt(localStorage.getItem('bcode_item'));
      let item_options = JSON.parse(localStorage.getItem('item_options'));
      let barcode_list = document.querySelectorAll(".barcode-list");
      let new_item_options = this.state.item_options;
      
      if(bcode_par_item!==bcode_item){
        localStorage.removeItem('bcode_par_item');
        localStorage.removeItem('bcode_item');
        if(new_item_options[bcode_par_item]===undefined){
          new_item_options[bcode_par_item] = [];
          new_item_options[bcode_par_item] = item_options[bcode_par_item];
        } 
        if(new_item_options[bcode_item]===undefined){
          new_item_options[bcode_item] = [];
          new_item_options[bcode_item] = item_options[bcode_item];
        }
      }else{
        localStorage.removeItem('bcode_par_item');
        localStorage.removeItem('bcode_item');
        new_item_options[bcode_item] = [];
        new_item_options[bcode_item] = item_options[bcode_item];
      }
      this.setState({item_options:new_item_options});
    }
    handlePaginationShortcut = (e)=>{
      
      // 33 - page up
      // 34 - page down
      // 35 - end
      // 36 - home
      if(e.which===33){
        let prev_link = this.state.prev_link;
        
        var page = prev_link.match(/\d+$/g);
        if(page && page[0]){
          let comming_page = parseInt(page[0]);
          if(comming_page>0){
            e.preventDefault();
            window.location.href = prev_link;
          }
        }else{
          window.location.href = prev_link;
        }
      }else if(e.which===34){
        let next_link = this.state.next_link;
        let total_page = this.state.total_page;
        var page = next_link.match(/\d+$/g);
        if(page){
          let comming_page = parseInt(page[0]);
          if(comming_page<=total_page){
            e.preventDefault();
            window.location.href = this.state.next_link;
          }
        }
      }else if(e.which===35){
        e.preventDefault();
        let last_page = this.state.next_link.replace(/\d+$/g, this.state.total_page);
        window.location.href = last_page;
      }else if(e.which===36){
        e.preventDefault();
        let first_page = this.state.next_link.replace(/\d+$/g, '1');
        window.location.href = first_page;
      }
    }
    handleGetActiveCount = () =>{
      
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            type:'Active'
        }
        let api = app_api+'/get_product_count_on_type';
        axios.post(api, send_data)
          .then(function (response) {
            localStorage.setItem('active_count',response.data.response);
            this.setState({active_count:response.data.response});
        }.bind(this)).catch(function(res){
            
        })
      
    }
    handleGetInActiveCount = () =>{
     
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          type:'Draft'
      }
      let api = app_api+'/get_product_count_on_type';
      axios.post(api, send_data)
        .then(function (response) {
          
          this.setState({draft_count:response.data.response});
          
      }.bind(this)).catch(function(res){
          
      })
    }
    handleGetLossMakingCount = () =>{
     
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          type:'Loss'
      }
      let api = app_api+'/get_product_count_on_type';
      axios.post(api, send_data)
        .then(function (response) {
          this.setState({loss_making_count:response.data.response});
      }.bind(this)).catch(function(res){
          
      })
    }
    componentDidMount(){
      document.addEventListener("keydown", this.handlePaginationShortcut);
      let curl = window.location.href;
      let url = curl.split("/"); 
      let current_url = url[url.length - 1];
      let prev_url = url[url.length - 2];
      this.setState({current_url:current_url});
      this.setState({prev_url:prev_url});
      let cpage;
      let current_page;
      let body = {};
      const inventory = curl.match(/products$/i);

      const product_add = curl.match(/products\/add$/i);
      const product_edit = curl.match(/products\/edit\/(\d+$)/i);

      const sold_out = curl.match(/products\/soldout$/i);
      
      const active_draft = curl.match(/products\/draft$/i);
      const loss_making = curl.match(/products\/loss$/i);
      const print_barcode = curl.match(/products\/print-barcode$/i);
      const inventory_page = curl.match(/products\/([active|draft|loss|soldout]+)\/page\/(\d+$)/i);
      
      let act_tab = 'active';
      if(active_draft){
        this.setState({active_tab:'draft'});
        act_tab = 'draft';
      }else if(sold_out){
        this.setState({active_tab:'soldout'});
        act_tab = 'soldout';
      }else if(loss_making){
        this.setState({active_tab:'loss'});
        act_tab = 'loss';
      }else if(inventory_page){
        this.setState({active_tab:inventory_page[1]});
        act_tab = inventory_page[1];
      }else if(product_add){
        this.setState({active_tab:'create_product'});
      }else if(product_edit){
        this.setState({active_tab:'create_product'});
      }

      if(print_barcode){
        this.setState({is_barcode_tab:true});
        let barcode_settings = {};
          barcode_settings = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                target : 'barcode',
             }
          let api = app_api+'/get_default_barcode_template';
          axios.post(api, barcode_settings)
            .then(function (response) {
              let cursor = response.data;
              
              this.setState({ 'barcode_template_data': cursor}); 
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
          
      }


      if(sold_out || inventory || active_draft || loss_making){
        this.handleGetActiveCount();
        this.handleGetInActiveCount();
        this.handleGetLossMakingCount();
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/products/'+act_tab+'/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            target:'product',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            type : act_tab,
            total_record : localStorage.getItem('total_record')
         }
      }else if(inventory_page){
        
        cpage = parseInt(inventory_page[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/products/'+act_tab+'/page/'+next;
        let prev_link = '/products/'+act_tab+'/page/'+prev;
        if(prev===1){
          prev_link = '/products';
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
        body = {
            page : cpage,
            target:'product',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            type : act_tab,
            total_record : localStorage.getItem('total_record')
         }
      }

      if(sold_out!==null || inventory!==null || active_draft!==null || inventory_page!==null || loss_making!==null){
        let api = app_api+'/inventory/page';
        axios.post(api, body)
          .then(function (response) {
              let total_record = response.data.total_record;
              localStorage.setItem('total_record',total_record);
              let page_count = response.data.number_of_page;
              this.setState({total_page:page_count});
              /* this.setState({active_count:response.data.active_count});
              this.setState({draft_count:response.data.draft_count});
              this.setState({loss_making_count:response.data.loss_making_count}); */
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({current_page:0});
              this.setState({total_page:0});
            }else{
              this.setState({inventory_list: response.data.data });
              if(cpage===page_count){
                this.setState({next_link:'#'});
              }
            }
        }.bind(this)).catch(function(res){
            if(res.response){
               //this.setState({loading_process:'Record(s) not found'});
            }
        })
      }

      const product_category = curl.match(/products\/category\/(\d+$)/i);
      const product_category_page = curl.match(/products\/category\/(\d+)\/page\/(\d+$)/i);
      let cat_id = 0 ;
      if(product_category){
        cat_id = product_category[1];
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/products/category/'+cat_id+'/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            target:'category',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            category_id : cat_id
         }
      }else if(product_category_page){
        cat_id = parseInt(product_category_page[1]);
        cpage = parseInt(product_category_page[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/products/category/'+cat_id+'/page/'+next;
        let prev_link = '/products/category/'+cat_id+'/page/'+prev;
        if(prev===1){
          prev_link = '/products/category/'+cat_id;
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
        body = {
            page : cpage,
            target:'category',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            category_id : cat_id
         }
      }

      if(product_category!==null || product_category_page!==null){
        this.setState({is_category:true});
        let api = app_api+'/products/category';
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
              this.setState({total_page:page_count});
              this.setState({active_count:response.data.active_count});
              this.setState({draft_count:response.data.draft_count});
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({current_page:0});
              this.setState({total_page:0});
            }else{
              this.setState({inventory_list: response.data.data });
              if(cpage===page_count){
                this.setState({next_link:'#'});
              }
            }
        }.bind(this)).catch(function(res){
            if(res.response){
               this.setState({loading_process:'Record(s) not found'});
            }
        })
      }

      let keys = "'product_sort_type','product_per_page'";
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:keys
      }
      let api = app_api+'/get_settings';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.product_sort_type!==undefined){
            let product_sort_type = resp.product_sort_type;
            this.setState({product_sort_type:product_sort_type});
          }
          if(resp.product_per_page!==undefined){
            let product_per_page = resp.product_per_page;
            this.setState({per_page:product_per_page});
          }
      }.bind(this)).catch(function(res){
          
      })
    } 

    handleCheckAll = (e) =>{
      let is_block_check = e.currentTarget.checked;
     
      this.setState({is_block_check:is_block_check});
      
      let qs = document.querySelectorAll(".item-checkbox");
      for (let i = 0; i < qs.length; i++) {
          qs[i].checked = is_block_check;
      }
    }

    handleItemCheck = (e) =>{
      let is_block_check = false;
      let qs = document.querySelectorAll(".item-checkbox");
      for (let i = 0; i < qs.length; i++) {
        if(qs[i].checked===true){
          is_block_check = true;
        }
      }
      this.setState({is_block_check:is_block_check});
    }

    handleDoneBulkAction = (e) =>{
      let opt = document.getElementById("bulk-action-select").value;
      let inventory_ids = [];
      let qs = document.querySelectorAll(".item-checkbox:checked");
      for (let index = 0; index < qs.length; index++) {
        let id = qs[index].id;
        inventory_ids.push(id);
      }
    
      if(opt==='delete'){
        if(inventory_ids.length>0){
          this.handleCloseDeletePop();
          let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              ids : inventory_ids,
              id :0,
              value : 'product',
          }
          let api = app_api+'/delete_inventory';
          axios.post(api, body)
            .then(function (response) {
            window.location.reload();
          }.bind(this)).catch(function(res){
              if(res.response){
                
              }
          })
        }
      }
    }

    handleBulkAction = (e) =>{
      let opt = document.getElementById("bulk-action-select").value;
      let inventory_ids = [];
      let qs = document.querySelectorAll(".item-checkbox:checked");
      for (let index = 0; index < qs.length; index++) {
        let id = qs[index].id;
        inventory_ids.push(id);
      }
      if(opt==='delete'){
        if(inventory_ids.length>0){
          var element = document.getElementById('delete_bulk_product');
          element.classList.remove("fade");
          element.style.display="block"; 
        }
      }else if(opt==='Active' || opt==='Draft'){
        if(inventory_ids.length>0){
          let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              ids : inventory_ids,
              value : opt,
          }
          let api = app_api+'/change_bulk_inventory_status';
          axios.post(api, body)
            .then(function (response) {
              window.location.reload();
          }.bind(this)).catch(function(res){
              if(res.response){
                
              }
          })
        }
      }
    }

    
    handleCloseDeletePop = (e) =>{
      var element = document.getElementById('delete_bulk_product');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    handleSortList = (e)=>{
      this.handleManageSort('availability');
      e.currentTarget.style.border = '2px solid #d7d7d7';
      let product_sort_type = this.state.product_sort_type;
      if(product_sort_type==='ASC'){
        product_sort_type = 'DESC';
      }else if(product_sort_type==='DESC'){
        product_sort_type = 'ASC';
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          name : 'product_sort_type',
          value : product_sort_type,
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
        window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }

    handleChangePerPage = (count) =>{
      this.setState({per_page:count});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          name : 'product_per_page',
          value : count,
      }

      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    
    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="inv-opt-block bl1">
                    {(this.state.is_category) &&
                     <div className="mod-title">
                      <a href="/products">
                        <FontAwesomeIcon icon={faArrowLeft} style={{color: '#808080',fontSize: '17px'}}/>  <span style={{fontSize: '17px'}}>Back</span>
                      </a>
                    </div>
                    }
                    {(this.state.is_barcode_tab===false && this.state.is_category===false) && 
                        <div className="mod-title"><span>Products</span></div>
                    }
                    {(this.state.is_barcode_tab===true && this.state.is_category===false) && 
                        <div className="mod-title"><span>Barcode Print</span></div>
                    }
                    </div>
                    {(this.state.active_tab!=='create_product' && this.state.is_category===false) && 
                    <>
                    <div className="inv-opt-block bl2 inventory-search">
                      {(this.state.is_barcode_tab===false && this.state.is_category===false) && 
                        <div className="mrtop-10">
                          <input type="text" onChange={this.handleInventorySearch} id="inventory-search-box" className="form-control" placeholder="Type to search..."/>
                        
                        </div>
                      }
                      {(this.state.is_barcode_tab===true && this.state.is_category===false) && 
                        <>
                        <span id="handleInventoryHideShow" onClick={this.handleInventoryHideShowOnAdd}></span>
                        <span id="handleInventoryHideShowOnRemove" onClick={this.handleInventoryHideShowOnRemove}></span>
                        <div className="input-group mrtop-10">
                          <Autocomplete
                            options={this.state.item_options}
                            title="Barcode Print"
                            data_target="barcode"
                            id={"item-name-0"}
                            className="auto-comp-opt"
                            data_id={0}
                            active_tab="inventory"
                            input_id={"item-box-0"}
                            target="item-box"
                            clsName = ""
                          />
                         
                        </div>
                        </>
                      }
                    </div>
                    <div className="inv-opt-block bl3">
                      <div className="pull-right inven-btn">
                          <Notification />
                      </div>
                    </div>
                    <div className="inv-opt-block bl4">
                      <div className="inven-btn">
                          {(this.state.is_barcode_tab===true && this.state.is_category===false) && 
                            <>
                            <button type="button" onClick={this.openPrintPreview} id="show-template-btn" className="btn btn-success nav-btn hide">Print Barcode</button>
                            <button type="button" id="show-template-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                            </>
                          }
                          {(this.state.is_barcode_tab===false && this.state.is_category===false) && 
                            <>
                            {<a href="/products/print-barcode"><button type="button" className="btn btn-success nav-btn">Print Barcode</button></a>}
                            </>
                          }
                      </div>
                    </div>
                    <div className="inv-opt-block inven-btn bl5">
                      <div className="contact-row pull-right">
                         
                          {(this.state.is_barcode_tab===false && this.state.is_category===false) && 
                            <>
                              {this.is_access('inventory-create') &&
                                <div className="dropdown">
                                  <button className="btn btn-success dropdown-toggle nav-btn" type="button" data-toggle="dropdown" onClick={this.handleOpenMenu}>Add Product
                                    <span className="caret"></span>
                                  </button>
                                  <div style={{marginTop:'-15px'}} className="hide" id="add-product-dd" >
                                  <FontAwesomeIcon icon={faCaretUp} style={{color: '#808080',marginLeft:'80px',fontSize: '30px'}}/>
                                  <ul className="list-group " style={{marginLeft:'10px',width:'160px',marginTop:'-12px'}}>
                                    <li className="list-group-item"><a href="/new-billing/2">Add product with bill entry</a></li>
                                    <li className="list-group-item"><a href="/products/add">Add product directly</a></li>
                                    
                                  </ul>
                                  </div>
                                </div>
                                
                              }
                            </>
                          }
                      </div>
                    </div>
                   </>
                 }
                  </div>
                </nav>
                {(this.state.active_tab==='active') &&
                  <div className="container-fluid">
                    <div className="content-area" style={{padding:'16px'}}>
                      <div className="main-content">
                        <DownloadProducts />
                      </div>
                    </div>
                  </div>
                }
                <div className="container-fluid">
               
                {(this.state.is_barcode_tab===false && this.state.search_count===0 && this.state.is_category===false) && 
                <>
                  <div className="nav-container">
                    <ul className="nav nav-pills" style={{position:'relative'}}>
                    
                      <li className={(this.state.active_tab==='active' || this.state.active_tab==='soldout')?'active':''}><a href="/products">Active ({this.state.active_count})</a></li>
                     
                      <li className={this.state.active_tab==='draft'?'active':''}><a href="/products/draft">Inactive ({this.state.draft_count})</a></li>
                      <li className={this.state.active_tab==='loss'?'active':''}><a href="/products/loss">Loss Making ({this.state.loss_making_count})</a></li>
                      
                    </ul>
                  </div>
                  {(this.state.active_tab==='active' || this.state.active_tab==='soldout') &&
                  <div className="nav-container">
                    <ul className="nav nav-pills" style={{position:'relative'}}>
                    
                      <li className={(this.state.active_tab==='active')?'active':''}><a href="/products">Available</a></li>
                     
                      <li className={this.state.active_tab==='soldout'?'active':''}><a href="/products/soldout">Sold Out</a></li>
                    
                    </ul>
                    
                  </div>
                }
                </>
                }
                  <div className="content-area">
                     <div className="main-content">
                        {(this.state.is_barcode_tab===false && this.state.search_count===0) && 
                        <>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            {(this.is_access('inventory-delete')) &&
                              <th scope="col" style={{width:'35px'}}>
                                <input type="checkbox" className="checkbox" onClick={this.handleCheckAll}/>
                              </th>
                            }
                            <th></th>
                            <th scope="col">PRODUCT</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col"  style={{cursor:'pointer'}} onClick={this.handleSortList}>INVENTORY <FontAwesomeIcon icon={faSort} className='sort-on-available'/></th>
                            
                            <th scope="col">SELLING PRICE</th>
                            <th scope="col"  style={{cursor:'pointer'}}  onClick={this.handleSortListOnMargin}>MARGIN <FontAwesomeIcon icon={faSort} className='sort-on-margin'/></th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.state.inventory_list!=='' && this.state.inventory_list.length > 0) ?this.state.inventory_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                              {(this.is_access('inventory-delete')) &&
                              <td>
                                <input type="checkbox" className="checkbox item-checkbox" onChange={this.handleItemCheck} defaultChecked={false} id={value.inventory_id}/>
                              </td>
                              }
                              <td width="70">
                                <div style={{backgroundImage:'url('+value.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width: '40px',border:'1px solid #d7d7d7'}}></div>
                              </td>

                              <td><Link style={{fontSize:'14px',fontWeight:'bold'}} link={(value.url!==undefined)?value.url:'/products/view/'+value.inventory_id} name={value.item_name} /></td>

                              <td id={value.category_id} data-id={value.inventory_id} data-value={value.category} style={{cursor:'pointer'}} dangerouslySetInnerHTML={{ __html: value.category}}>
                              </td>
                           
                              <td> 
                                {(value.varient>0) &&
                                <>
                                    <span>{value.availability+' in stock for '+value.varient + ' variants'}</span>
                                  
                                </>
                                }
                                {(value.varient===0) &&
                                <>
                                {(value.child_barcode_info.length>1) &&
                                  <DrawerLink target="different_barcode" url='' name={value.availability+' in stock'} schema='' info={value.child_barcode_info}  page_title={value.item_name}/>
                                }
                                {(value.child_barcode_info.length<=1) &&
                                    <span>{value.availability+' in stock'}</span>
                                }
                                </>
                                }
                              </td>
                              <td>{value.selling_price}</td>
                              <td>{utils.currency_format(value.margin)}%</td>
                              {(this.state.is_category===false) &&
                              <td>
                                  <Tooltip target="inventory" id={value.inventory_id} index={index} view={"/products/view/"+value.inventory_id} edit={"/products/edit/"+value.inventory_id}  delete={{active_tab:'inventory',id:value.inventory_id,data_value:'inventory'}}  item_count={this.state.inventory_list.length}/>

                              </td>
                              }
                              {(value.parent_id===0 && this.state.is_category) &&
                              <td>
                                  <Tooltip target="inventory" id={value.inventory_id} index={index} view={"/products/view/"+value.inventory_id} edit={"/products/edit/"+value.inventory_id}  delete={{active_tab:'inventory',id:value.inventory_id,data_value:'inventory'}}  item_count={this.state.inventory_list.length}/>
                              </td>
                              }
                              {(value.parent_id!==0 && this.state.is_category) &&
                              <td>
                                  <Tooltip target="inventory" id={value.inventory_id} index={index} view={"/variant/view/"+value.parent_id+"/"+value.inventory_id} edit={"/variant/edit/"+value.parent_id+"/"+value.inventory_id}  delete={{active_tab:'inventory',id:value.inventory_id,data_value:'variation'}}  item_count={this.state.inventory_list.length}/>
                              </td>
                              }
                            </tr>
                          )
                         }) : <tr><td colSpan="11" className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                        </table>
                        <div style={{display:'flow-root'}}>
                        <div className="pull-left" style={{display:'flex',marginLeft:'15px',marginTop:'-5px'}}>
                          <select className="form-control" onChange={(e)=>this.handleChangePerPage(e.target.value)} value={this.state.per_page}>
                              <option value="25">25 per page</option>
                              <option value="50">50 per page</option>
                              <option value="100">100 per page</option>
                              <option value="200">200 per page</option>
                              <option value="300">300 per page</option>
                          </select>
                         
                        </div>
                      {(this.state.is_block_check===true && this.is_access('inventory-delete')==true) &&
                        <div className="pull-left" style={{display:'flex',marginLeft:'15px',marginTop:'-5px'}}>
                          <select className="form-control" id="bulk-action-select">
                              <option value="">Select Action</option>
                              <option value="delete">Delete</option>
                              <option value="Active">Activate</option>
                              <option value="Draft">Deactivate</option>
                          </select>
                          <button className="btn btn-success mrleft-5" onClick={this.handleBulkAction}>Done</button>
                        </div>
                      }
                      {(this.state.inventory_list && this.state.inventory_list.length > 0) &&
                        <ListCount list={this.state.inventory_list}/>
                      }
                      <div className="page-nav" style={{marginRight:'15px'}}>
                        <div className="navd">
                          <a href={this.state.prev_link}>
                            <div className="navd">
                              <FontAwesomeIcon className="cont" icon={faCaretLeft}/>
                            </div>
                          </a>
                        </div>
                        <div className="navd center"><p className="msg">{this.state.current_page} of {this.state.total_page}</p></div>
                        <div className="navd">
                          <a href={this.state.next_link}>
                            <div className="navd">
                              <FontAwesomeIcon  className="cont" icon={faCaretRight}/>
                            </div>
                          </a>
                        </div>
                      </div>
                      </div>
                      </>
                    }
                    {(this.state.search_count>0) &&
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">PRODUCT</th>
                            <th scope="col">CATEGORY</th>
                            
                            <th scope="col">INVENTORY</th>
                            <th scope="col">SELLING PRICE</th>
                            <th scope="col">STATUS</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                            {(this.state.inventory_search_items.length>0) &&
                              <>
                                {this.state.inventory_search_items}
                              </>
                            }
                            {(this.state.inventory_search_items.length===0) &&
                              <tr>
                                <td className="text-center" colSpan="7">{this.state.loading_process}</td>
                              </tr>
                            }

                        </tbody>
                      </table>
                    }
                    {(this.state.is_barcode_tab===true) && 
                      <>
                     
                      <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">CATEGORY</th>
                            
                            <th scope="col">STOCK</th>
                            <th scope="col">VARIENT</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">Qty</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody id="barcode-print-list">
                          <tr><td colSpan="7" className="text-center">Select Item form above box to generate barcode</td></tr>
                        </tbody>
                        <tfoot className="hide" id="total-bp-foot">
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>Total: <label id="barcode-print-total"></label></th>
                          <th></th>
                          
                        </tfoot>
                      </table>

                      <div id="barcode-print-preview" className="modal fade"  style={{overflowY:'scroll'}}>
                        <div className="modal-dialog" style={{textAlign:'center'}} >
                          <div className="modal-content">
                           <div className="modal-header bg-none">
                              <div className="row">
                                <div className="col-md-4 mrtop-5">
                                  <h4 style={{color:'black',textTransform:'uppercase'}}>
                                  {(this.state.barcode_template_data && this.state.barcode_template_data[0] && this.state.barcode_template_data[0].name) &&
                                    this.state.barcode_template_data[0].name
                                  }
                                  </h4>
                                </div>
                             
                                <div className="col-md-8">
                                  <button type="button" onClick={this.handlePrint} className="btn btn-success pull-right mrleft-5">Print</button>
                                  <button type="button" onClick={this.closeModel} className="btn btn-secondary pull-right">Cancel</button>
                                </div>
                              </div>
                            </div>
                         
                            <div className="modal-body" style={{padding:'15px',background:'#dedede'}} id="main-container" >
                                {this.barcode_template}
                            </div>
                          </div>
                        </div>
                      </div>  
                      </>
                    }
                     </div>
                  </div>
                </div>
              </div>
              
            <div id="delete_bulk_product" className="modal fade">
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <div className="icon-box">
                    <span className="material-icons">&times;</span>
                  </div>            
                  <h4 className="modal-title w-100">Are you sure?</h4>  
          
                </div>
                <div className="modal-body">
                  <p style={{whiteSpace: 'break-spaces'}} >Do you really want to delete these records? This process cannot be undone</p>
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-secondary" onClick={this.handleCloseDeletePop}>No</button>
                  <button type="button" className="btn btn-danger" onClick={this.handleDoneBulkAction}>Yes</button>
                </div>
              </div>
            </div>
          </div>  

            </div>
        );
    }
}